import { mq } from '../../../cssInJs';

export default {
    timetag__articles: {
        maxWidth: '960px',
        margin: 'auto',
    },

    timetag__articles__c: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',

        '.article__tile': mq({
            flex: ['1 0 100%', null, '1 0 50%', '1 0 33.3%'],
            maxWidth: ['100%', null, '50%', '33.3%' ],
            padding: '0.8rem 0.8rem',
            display: 'flex',
            flexDirection: 'column',
        })
    },
    timetag__articles__loading : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },

    timetag__articles__more__btn : {
        textAlign: 'center'
    }
}