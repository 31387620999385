import { mq } from '../../../../cssInJs';

export default {
    
    selected__articles : {
        maxWidth : '960px',
        margin: 'auto',
        //overflow: 'auto'
    },
    
    selected__articles__error: {
        fontSize: '2rem',
        textAlign: 'center',
        color: 'red'
    },
    selected__articles__c: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',

        //marginLeft: '-0.8rem',
        //marginRight: '-0.8rem',

        '.article__tile': mq({
            flex: ['1 0 100%', null, '1 0 50%', '1 0 33.3%'],
            maxWidth: ['100%', null, '50%', '33.3%' ],
            padding: '0.8rem 0.8rem',
            display: 'flex',
            flexDirection: 'column',
        })

    }
}