/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useMemo, memo } from 'react';
import utils from 'utils';
import { SiteLink } from '../../../components';
//import env from '../../../../env';
import style from "./articleTileStyle";
import { MdCameraAlt } from "react-icons/md";


const ArticleTile = (({ article, specialFunction = null }) => {

    const imageUrl = utils.site.resourcePath(article.mainImageUrl);
    const alpha = useMemo(() => {
        let catName = "";
        if (article.categoryName && article.categoryName.length > 1) {
            catName = (article.categoryName[0]).toLowerCase();
        }
        return catName;

        // eslint-disable-next-line
    }, []);

    const tooltip = article.photoCreditSource + (article.photoCreditName ? ' ' + article.photoCreditName : '') + (article.photoCreditDate ? ' [' + article.photoCreditDate + ']': '');

    return (
        <div className={`article__tile alpha-${alpha}`} css={style.article__tile} id={`article__tile__${article.postId}`} onClick={(e) => {
            e.preventDefault();
            if(specialFunction){
                specialFunction(article.postId);
            }
        }}>
            <SiteLink to={`/articles/${article.postSlug}`}>
                <div className='article__tile__bg' css={style.article__tile__bg}>
                    <div style={{ overflow: 'hidden', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                        <div style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }} className='article__tile__bg__inner' css={style.article__tile__bg__inner}></div>
                    </div>
                    <div className='article__tile__svg'>
                        <div className='article__tile__svg_c'>
                            <MdCameraAlt />
                            <div className='article__tile__tip'>
                                {tooltip}
                            </div>
                        </div>
                    </div>
                </div>
            </SiteLink>
            <div className="article__tile__category" css={style.article__tile__category}>
                <SiteLink to={`${article.categorySlug}`}> {article.categoryName + " >"} </SiteLink>
            </div>
            <div className='article__tile__des' css={style.article__tile__des}>
                <SiteLink to={`/articles/${article.postSlug}`}>
                    <div className='article__tile__title' css={style.article__tile__title}>
                        {article.title}
                    </div>
                    <div className='article__tile__read' css={style.article__tile__read}>
                        READ MORE+
                    </div>
                </SiteLink>
            </div>
        </div>
    )
});

export default memo(ArticleTile);
//export default ArticleTile;