import { mq } from '../../../../cssInJs';

export default{
    banner__articlescontainer :{
        
    },

    article__title : mq({
        backgroundColor: 'rgba(0,0,0,0.3)',
        textAlign: 'center',
        padding: '0.5rem 0rem',
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#eee',
        fontWeight: '700',
        fontSize: ['16px',null,'28px']
    })
}