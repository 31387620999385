import { mq } from '../../../cssInJs';

export default{
    
    article__search__result__view : {
        maxWidth: '960px',
        margin: 'auto',
        position : 'relative',
        marginBottom: '2rem',
        //paddingLeft: '1rem',
        //paddingRight: '1rem',
        overflow: 'hidden'
    },
    
    search__result__text : {
        //textAlign: 'center'
    },

    article__search__result__view__loading : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    article__search__result__c: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',

        '.article__tile': mq({
            flex: ['1 0 100%', null, '1 0 50%', '1 0 33.3%'],
            maxWidth: ['100%', null, '50%', '33.3%' ],
            padding: '0.8rem 0.8rem',
            display: 'flex',
            flexDirection: 'column',
        })
    },
    article__search__result__more__btn : {
        textAlign: 'center'
    },

    article__no__result : {
        textAlign : 'center',
        color: '#d2691e',
        marginTop: '1rem'
    }
}