/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useRouter, Loading, MetaTag } from "../../../components";
import { useMemo, useState, useEffect, Fragment } from "react";
import env from "../../../env";
import style from "./articleSearchResultStyle";
import ArticleTile from "../articleTile/articleTile";
import { ViewPartial } from "../../../cms/pages/partials/viewPartial";
import { useCallback } from "react";
import { useStore } from "../../../store";
import utils from "../../../utils";

export function ArticleSearchResultView() {
  const { dispatch, state } = useStore();
  const { query } = useRouter();
  const _keyword = query.q;
  const _sortBy = query.s || "date-desc";
  const [searchResult, setSearchResult] = useState(() => {
    if (state.searchResultState) {
      return state.searchResultState.searchResult;
    }
    return null;
  });
  const [pending, setPending] = useState(() => {
    if (state.searchResultState) {
      return false;
    }
    return true;
  });
  const [tirgger, setTrigger] = useState(() => {
    if (state.searchResultState) {
      return 0;
    } else {
      return 1;
    }
  });

  const [filterValue, setFilterValue] = useState(() => {
    if (state.searchResultState) {
      return state.searchResultState.filterValue;
    } else {
      return {
        keyword: _keyword,
        sortBy: _sortBy, //'date-desc',
        pageNo: 1,
      };
    }
  });

  function changeKeyword(e) {
    e.preventDefault();
    setFilterValue({
      ...filterValue,
      [e.target.name]: e.target.value,
    });
  }

  function changeSortBy(e) {
    e.preventDefault();
    setFilterValue({
      ...filterValue,
      [e.target.name]: e.target.value,
    });
    setSearchResult(null);
    setPending(true);
    setTrigger(tirgger + 1);
  }

  function search(e) {
    e.preventDefault();
    setSearchResult(null);
    setPending(true);
    setTrigger(tirgger + 1);
  }

  const addMoreFunction = useCallback(() => {
    setFilterValue({ ...filterValue, pageNo: filterValue.pageNo + 1 });
    setPending(true);
    setTrigger(tirgger + 1);

    // eslint-disable-next-line
  }, [tirgger]);

  const specialFunction = useCallback((p) => {
    dispatch({
      type: "SET_STATE",
      payload: { lastClickArticleTileInSearch: "article__tile__" + p },
    });

    // eslint-disable-next-line
  }, []);

  const searchResultMemmo = useMemo(() => {
    return (
      <SearchResult
        searchResult={searchResult}
        addMoreFunction={addMoreFunction}
        pending={pending}
        specialFunction={specialFunction}
      />
    );

    // eslint-disable-next-line
  }, [searchResult, pending]);

  const getMetaTag = useCallback(() => {
    return {
      //title: (article.title.length >= 15 ? article.title.substring(0, 15) : article.title) + " | " + env.titleSuffix,
      title: "Search Result | " + env.titleSuffix,
      description: "Search Result",
      keywords: "",
      heroImageUrl: utils.site.resourcePath(
        "/sites/adelaideaz/media/images/banners/fickle-finger-banner-v3.jpg"
      ),
      language: "English",
      country: "Australia",
      siteName: env.siteName,
      url: utils.site.resourcePath("/search-result"),
    };
  }, []);

  useEffect(() => {
    if (tirgger === 0) {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      const res = await fetch(env.apiBase + "/api/article/search", {
        method: "post",
        signal: signal,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          //category: atdwType,
          //town: hideSearch === 'off' ? 'ALL' : town
          //categoryCode : null,
          pageNo: filterValue.pageNo,
          keyword: filterValue.keyword,
          sortBy: filterValue.sortBy,
        }),
      });

      const data = await res.json();

      if (
        searchResult &&
        searchResult.articles &&
        searchResult.articles.length > 0 &&
        filterValue.pageNo > 1
      ) {
        //setSearchResult(data);
        searchResult.articles = searchResult.articles.concat(data.articles);
        searchResult.searchParams = data.searchParams;
        searchResult.totalCnt = data.totalCnt;
        setSearchResult(JSON.parse(JSON.stringify(searchResult)));
        dispatch({
          type: "SET_STATE",
          payload: {
            searchResultState: {
              searchResult: searchResult,
              filterValue: filterValue,
              calledPageUrl: "/search-result",
            },
          },
        });
      } else {
        setSearchResult(data);
        if (tirgger > 1) {
          window.history.pushState(
            {},
            "",
            "/search-result?q=" +
              filterValue.keyword +
              "&s=" +
              filterValue.sortBy
          );
        }

        dispatch({
          type: "SET_STATE",
          payload: {
            searchResultState: {
              searchResult: data,
              filterValue: filterValue,
              calledPageUrl: "/search-result",
            },
          },
        });
      }
      setPending(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [tirgger]);

  return (
    <Fragment>
      <MetaTag data={getMetaTag()} />
      <div className="container">
        <div
          className="article__search__result__view"
          css={style.article__search__result__view}
        >
          <h2 className="search__result__text" css={style.search__result__text}>
            Search Result
          </h2>
          <form className="row g-3" onSubmit={(e) => search(e)}>
            <div className="col-md-8 col-12">
              <label htmlFor="keyword" className="form-label">
                Keyword
              </label>
              <input
                type="text"
                className="form-control"
                id="keyword"
                name="keyword"
                disabled={pending}
                value={filterValue.keyword}
                onChange={changeKeyword}
                required
              />
            </div>
            <div className="col-md-4 col-12">
              <label
                htmlFor="sortBy"
                className="form-label"
                placeholder="Sort By"
              >
                {" "}
                Sort By{" "}
              </label>
              <select
                id="sortBy"
                className="form-control"
                onChange={changeSortBy}
                name="sortBy"
                value={filterValue.sortBy}
                disabled={pending}
              >
                <option value="date-desc"> Recently Added </option>
                <option value="az"> Alphabetic A-Z </option>
                <option value="za"> Alphabetic Z-A </option>
              </select>
            </div>
          </form>
          {pending && (
            <div
              className="article__search__result__view__loading"
              css={style.article__search__result__view__loading}
            >
              <Loading />
            </div>
          )}
          {searchResult &&
            searchResult.articles &&
            searchResult.articles &&
            searchResultMemmo}
        </div>
      </div>
      <ViewPartial />
    </Fragment>
  );
}

function SearchResult({
  searchResult,
  addMoreFunction,
  pending,
  specialFunction,
}) {
  const articles = searchResult.articles;
  const totalCnt = searchResult.totalCnt;
  const pageSize = 12;
  const totalCntSize = Math.floor(totalCnt / pageSize);

  return (
    <Fragment>
      <div
        className="article__search__result__c"
        css={style.article__search__result__c}
      >
        {articles.map((item, index) => {
          return (
            <ArticleTile
              key={index}
              article={item}
              specialFunction={specialFunction}
            />
          );
        })}
      </div>
      {totalCnt === 0 && (
        <div className="article__no__result" css={style.article__no__result}>
          No matching item.
        </div>
      )}
      {totalCntSize > pageSize && (
        <div
          css={style.article__search__result__more__btn}
          className="article__search__result__more__btn"
        >
          <button
            className="btn btn-primary"
            onClick={addMoreFunction}
            disabled={pending}
          >
            {pending ? "Loading...." : "MORE"}
          </button>
        </div>
      )}
    </Fragment>
  );
}
