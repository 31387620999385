import { variables, mq } from '../../../cssInJs';

export default {
    article__tile: {
        cursor: 'pointer',
        '&:hover': {
            '.article__tile__bg__inner': {
                transform: 'scale(1.02)'
            }
        }
    },

    article__tile__bg: {
        position: 'relative',
        //overflow: 'hidden',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            backgroundImage: 'url("/assets/logos/logo.png")',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: '-1'
        },
        '.article__tile__svg': {
            position: 'absolute',
            top: '5px',
            right: '5px',
            '&:hover' : {
                '.article__tile__svg_c .article__tile__tip' : {
                    visibility: 'visible'
                }
            },
            '.article__tile__svg_c' : {
                position : 'relative',
                '.article__tile__tip' : {
                    position: 'absolute',
                    top: '100%',
                    left: '-178px',
                    //left: '50%',
                    //transform : 'translateX(-50%)',
                    width : '210px',
                    color : 'white',
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    padding : '0.5rem',
                    fontSize : '0.8rem',
                    lineHeight : '1.1',
                    zIndex: 1,
                    borderRadius: '6px',
                    visibility: 'hidden'
                }
            },
            'svg': {
                color: 'white',
                fontSize: '2rem',
                opacity: '0.7'
            }
        }
    },

    article__tile__category: {
        color: 'white',
        textAlign: 'center',
        padding: '0.3rem',
        fontWeight: 700,
        textTransform: 'uppercase',
        'a': {
            color: 'white',
            '&:hover': {
                textDecoration: 'none'
            }
        }
    },

    article__tile__des: {
        marginTop: '10px',
        padding: '8px 15px 15px',
        boxShadow: '1px 1px 10px rgba(0,0,0,.08)',
        flex: '1 0 auto',
        'a': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            '&:hover': {
                textDecoration: 'none'
            }
        }
    },

    article__tile__title: mq({
        fontFamily: variables.familyHeader,
        fontSize: ['1.3rem',null, '1.2rem', '1.2rem', '1.3rem'],
        fontWeight: 700,
        lineHeight: 1.1,
        color: variables.fontColor,
        flex: '1 0 auto'
    }),

    article__tile__read: {
        color: '#00004c',
        fontWeight: 700,
        padding: '0.3rem',
        border: `1px solid #00004c`,
        textAlign: 'center',
        marginTop: '1rem'
    },

    article__tile__bg__inner: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingTop: '56.25%',
        transition: 'all 0.2s ease-out',
    }
}