import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/past', title: '19', title2 : 'th', title3 : 'Century', icon: 'past-black.png', iconHover: 'past-red.png' },
    { url: '/present', title: '20', title2 : 'th', title3 : 'Century', icon: 'present-black.png', iconHover: 'present-red.png' },
    { url: '/future', title: '21', title2 : 'st', title3 : 'Century', icon: 'future-black.png', iconHover: 'future-red.png' },
    { url: '/about', title: 'ABOUT', title2 : '', title3 : '', icon: 'about-black.png', iconHover: 'about-red.png' },
    { url: '#contact', title: 'CONTACT', title2 : '', title3 : '', icon: 'contact-black.png', iconHover: 'contact-red.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>
          {link.title}
          {
            link.title2 && link.title2 !== null &&
            <sup> {link.title2}</sup>
          }
          {link.title3}
        </div>
      </SiteLink>
    </li>
  );

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className="bottomMenu">
        {/* <div className="bottomMenu__bar">
          <div className="bottomMenu__line1"></div>
          <div className="bottomMenu__line2"></div>
        </div> */}
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
      {/* <div className="bottomMenuPadding"></div> */}
    </>
  );
}
