/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from "./selectedArticlesStyle";
import { memo } from 'react';
import utils from 'utils';
import cmsUtils from '../../cmsUtils';
import env from '../../../../env';
import { useEffect, useState } from 'react';
import ArticleTile from "../../../article/articleTile/articleTile";
import { Fragment } from 'react';

const SelectedArticles = ((props) => {
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'selected__articles', item.cssClass || item.anchorName || '');
    const _selectedArticles = cmsUtils.payload(item, 'SelectedArticles') || '';
    const selectedArticles = _selectedArticles !== '' ? _selectedArticles.split(',') : [];
    const [articles, setArticles] = useState();

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = async () => {
            const res = await fetch(env.apiBase + "/api/article/getselected", {
                method: 'post',
                signal: signal,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    postIds: selectedArticles
                })
            });
            const data = await res.json();
            setArticles(data);
            //console.log("data", data);
        };

        fetchData();
        return function cleanup() {
            abortController.abort();
        }

        // eslint-disable-next-line
    }, []);
    
    return (
        <div className={cssClass} css={style.selected__articles} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                articles &&
                <Fragment>
                    {
                        (articles.statusCode && articles.statusCode === 500) ?
                            <div className='selected__articles__error' css={style.selected__articles__error}> 500 error </div> :
                            <div className='selected__articles__c' css={style.selected__articles__c}>
                                {
                                    articles.map((item, index) => {
                                        return (
                                            // <FeaturedArticle key={index} article={item} />
                                            <ArticleTile key={index} article={item} />
                                        )
                                    })
                                }
                            </div>
                    }
                </Fragment>
            }

        </div>
    )
});

export default memo(SelectedArticles);