/** @jsx jsx */
import { jsx } from '@emotion/core';
import { AiOutlineSearch } from "react-icons/ai";
import style from "./articleSearchBoxStyle";
import { useRouter } from '../../../components';
import {useState, memo } from 'react';

const ArticleSearchBox = (() => {
    const [keyword, setKeyword] = useState('');
    const { navigate } = useRouter();

    function search(e) {
        e.preventDefault();
        setKeyword('');
        navigate('/search-result?q=' + encodeURIComponent(keyword));
    }

    function onChange(e) {
        setKeyword(e.target.value);
    }

    return(
        <div className='search__box__w' css={style.search__box__w}>
            <div style={{ position: 'relative' }} className='search__box__c' css={style.search__box__c}>
                <div className="input-group search__box__i__g" css={style.search__box__i__g}>
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="">
                            <AiOutlineSearch />
                        </span>
                    </div>
                    <form onSubmit={(e) => search(e)}>
                        <input type="text" className="form-control" placeholder='Search' value={keyword} onChange={onChange} />
                    </form>
                </div>
            </div>
        </div>
    )

});

export default memo(ArticleSearchBox);