/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './timetagArticlesStyle';
import { memo, useEffect, useState, useCallback } from 'react';
import env from '../../../env';
import { Loading } from '../../../components';
//import utils from 'utils';
//import { MdCameraAlt } from "react-icons/md";
import ArticleTile from '../../../site/article/articleTile/articleTile';
import { Fragment } from 'react';

const TimetagArticles = (({ timeTag }) => {

    const [result, setResult] = useState();
    const [pageNo, setPageNo] = useState(1);
    const [pending, setPending] = useState(true);

    const addMoreFunction = useCallback(() => {
        setPending(true);
        setPageNo(pageNo + 1);

        // eslint-disable-next-line
    }, [pageNo]);

    useEffect(() => {

        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = async () => {

            const res = await fetch(env.apiBase + "/api/article/gettimetagged", {
                method: 'post',
                signal: signal,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    //category: atdwType,
                    //town: hideSearch === 'off' ? 'ALL' : town
                    //categoryCode : null,
                    pageNo: pageNo,
                    timeTag: timeTag,
                    //keyword: filterValue.keyword,
                    sortBy: 'date-desc'
                })
            });

            const data = await res.json();

            if (result && result.articles && result.articles.length > 0 && pageNo > 1) {
                result.articles = result.articles.concat(data.articles);
                result.searchParams = data.searchParams;
                result.totalCnt = data.totalCnt;
                setResult(JSON.parse(JSON.stringify(result)));
            }
            else {
                setResult(data);
            }

            setPending(false);
        };

        fetchData();

        return function cleanup() {
            abortController.abort();
        }

        // eslint-disable-next-line
    }, [pageNo]);

    return (
        <div className='timetag__articles' css={style.timetag__articles}>

            {
                pending &&
                <div className='timetag__articles__loading' css={style.timetag__articles__loading}>
                    <Loading />
                </div>
            }

            {
                result && result.articles &&
                <SearchResultMemo result={result} pending = {pending} addMoreFunction = {addMoreFunction} />

            }
        </div>
    )
});

export default memo(TimetagArticles);

const SearchResultMemo = memo(({ result, pending, addMoreFunction }) => {

    const articles = result.articles;
    const totalCnt = result.totalCnt;
    const pageSize = 12;
    const totalCntSize = Math.floor((totalCnt / pageSize));

    return (
        <Fragment>
            <div className='timetag__articles__c' css={style.timetag__articles__c}>
                {
                    articles.map((item, index) => {
                        return (
                            <ArticleTile key={index} article={item} />
                        )
                    })
                }
            </div>
            {
                totalCntSize > pageSize &&
                <div css={style.timetag__articles__more__btn} className='timetag__articles__more__btn'>
                    <button className="btn btn-primary" onClick={addMoreFunction} disabled={pending}>
                        {pending ? 'Loading....' : 'MORE'}
                    </button>
                </div>
            }
        </Fragment>
    )
});