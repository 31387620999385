/** @jsx jsx */
import { jsx } from '@emotion/core';
//import { useState } from 'react';
import utils from 'utils';
import ArticleSearchBox from '../../../../site/article/articleSearchBox/articleSearchBox';
//import { useRouter } from '../../../../components';
//import env from '../../../../env';
//import style from "./searchBoxStyle";
//import cmsUtils from '../../cmsUtils';
//import { AiOutlineSearch } from "react-icons/ai";
//import { useCallback } from 'react';

export function SearchBox(props) {
    
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'search__box', item.cssClass || item.anchorName || '');

    return (
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <ArticleSearchBox />
        </div>
    )
}