import { mq } from '../../../cssInJs';

export default {
    article__view: {
        //paddingTop: '10px'
    },
    article__view__contetns: {
        maxWidth: '960px',
        margin: 'auto',
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    article__view__categories: {
        display: 'flex'
    },

    article__view__category: {
        color: '#fff',
        padding: '0.3rem 1rem',
        margin: '0.3rem',
        borderRadius: '1rem',
        '&:first-of-type': {
            marginLeft: '0px'
        },
        '&:hover': {
            textDecoration: 'none',
            color: 'white',
        }
    },

    article__view__post__img: {
        margin: '0.5rem 0'
    },

    article__view__related__posts : {

    },

    article__view__related__posts__c: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: '-0.8rem',
        marginRight: '-0.8rem',
        //overflow: 'hidden',
        '.article__tile': mq({
            flex: ['1 0 100%', null, '1 0 50%', '1 0 33.3%'],
            maxWidth: ['100%', null, '50%', '33.3%' ],
            padding: '0.8rem 0.8rem',
            display: 'flex',
            flexDirection: 'column',
        })
    },

    article__view__post__contetns : {
        fontWeight: 500
    }
}