const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorRed : '#ee1f3b',
  colorDarkBlue : '#00004c'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"bree-serif",serif',

  primaryColor: colors.colorRed,
  fontColor: '#414042',
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;