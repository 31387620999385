const config = {
  isProd: process.env.NODE_ENV === "production",
  locale: getLocale(),
  siteName: process.env.REACT_APP_SITENAME,
  titleSuffix: process.env.REACT_APP_TITLESUFFIX,
  siteCode: process.env.REACT_APP_SITECODE,
  apiBase: process.env.REACT_APP_API_ENDPOINT,
  resourceBase: process.env.REACT_APP_RESOURCE_BASEURL,
  baseOrigin: getBaseUrl(),
  cmsEndpoint: process.env.REACT_APP_CMS_ENDPOINT,
  ver: process.env.REACT_APP_VERSION,
  updateDate: process.env.REACT_APP_UPDATED_AT,
  stripeKey: process.env.REACT_APP_STRIPE_KEY
  
  //bvClientId: process.env.REACT_APP_BAZZAR_CLIENT_ID,
  //bvSiteId: process.env.REACT_APP_BAZZAR_SITE_ID,
  //bvEnv: process.env.REACT_APP_BAZZAR_ENV,
  //bvLocale: getLocale() === 'nz' ? 'en_NZ' : 'en_AU',
}

function getLocale() {
  return process.env.REACT_APP_COUNTRY_LOCALE || getCountry()
}

function getCountry() {
  if (typeof window !== `undefined`) {
    if (window.location.hostname.toLowerCase().indexOf(".nz") >= 0) return 'nz';
    if (window.location.hostname.toLowerCase().indexOf("-nz") >= 0) return 'nz';
    if (window.location.search === '?nz=true') return 'nz';
  }
  return 'au';
}

function getBaseUrl() {
  if (typeof window !== `undefined`) {
  return window.location.origin;
  }
  return process.env.GATSBY_BASEURL;
}

console.log(process.env, config)
export default {
  ...config,
  getDevCartId: function() {
    return !config.isProd && typeof window !== `undefined` ? window.localStorage.getItem('cart') : null
  },
  setDevCartId: function(cartId) {
    if(!config.isProd && typeof window !== `undefined`) window.localStorage.setItem('cart', cartId)
  }
}