import { variables, mq } from '../../../cssInJs'

export default{
    
    search__box__w : mq({
        position: 'sticky',
        top : ['54px',null,'69px'],
        zIndex: 99
    }),
    
    search__box__c : {
        backgroundColor: variables.colorDarkBlue,
        paddingTop: '1rem',
        paddingBottom: '1rem'
    },
    search__box__i__g : {
        //maxWidth: '360px',
        //margin: 'auto',
        justifyContent: 'center',
        'form' : {
            flex : '1 0 auto',
            maxWidth: '320px'
        },
        '.input-group-text' : {
            backgroundColor: '#00b17d',
            border: 'unset',
            borderRadius: 'unset',
            color: 'white'
        },
        '.form-control' : {
            borderRadius : 'unset'
        }
    }
}