/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './articleViewStyle';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import env from '../../../env';
import { useRouter, Loading, SiteLink, MetaTag, NotFound } from '../../../components';
import ArticleSearchBox from '../../../site/article/articleSearchBox/articleSearchBox';
import ArticleTile from '../../../site/article/articleTile/articleTile';
import { ViewPartial } from '../../../cms/pages/partials/viewPartial';
import utils from '../../../utils';
import { Helmet } from 'react-helmet-async';

const ArticleView = (() => {

    const { query } = useRouter();
    const slug = query.slug || '';
    const [article, setArticle] = useState();
    const getMetaTag = useCallback(() => {

        if (!article) {
            return;
        }

        return {
            //title: (article.title.length >= 15 ? article.title.substring(0, 15) : article.title) + " | " + env.titleSuffix,
            title: article.title + " | " + env.titleSuffix,
            description: article.title,
            keywords: '',
            heroImageUrl: utils.site.resourcePath(article.mainImageUrl),
            language: 'English',
            country: 'Australia',
            siteName: env.siteName,
            url: utils.site.resourcePath('/articles/' + article.postSlug),
        }

        // eslint-disable-next-line
    }, [article]);

    useEffect(() => {
        
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = async () => {
            const res = await fetch(env.apiBase + "/api/article/getarticle/" + slug, {
                method: 'get',
                signal: signal,
                headers: { 'Content-Type': 'application/json' }
            });

            const data = await res.json();
            setArticle(data);
        };

        fetchData();

        return function cleanup() {
            abortController.abort();
        }

        // eslint-disable-next-line
    }, [slug]);

    if (!article) {
        return (
            <Loading />
        )
    }
    else if(article && article.statusCode && article.statusCode === 500){
        return(
            <NotFound />
        )
    }

    return (
        <Fragment>
            <MetaTag data={getMetaTag()} />
            <ArticleStructureMemo article = {article} />
            <ArticleSearchBox />
            <div className='contentPanel'>
                <div className='article__view__contetns' css={style.article__view__contetns}>
                    <div className="article__view__categories" css={style.article__view__categories}>
                        {
                            article.categories.map((item, index) => {
                                return (
                                    <ArticleCategory key={index} category={item} />
                                )
                            })
                        }
                    </div>
                    <h1> {article.title} </h1>

                    {
                        article.mainImageUrl && article.mainImageUrl !== '' &&
                        <div className="article__view__post__img" css={style.article__view__post__img}>
                            <img style={{ width: '100%', verticalAlign: 'middle' }} src={env.resourceBase + article.mainImageUrl} alt={article.title} />
                        </div>
                    }
                    <div className="article__view__post__contetns" css={style.article__view__post__contetns} dangerouslySetInnerHTML={{ __html: article.postContent }}></div>
                </div>
            </div>

            {
                article.relatedPosts && article.relatedPosts.length > 0 &&
                <div className='contentPanel' style={{ backgroundColor: 'white' }}>
                    <div className='article__view__contetns' css={style.article__view__contetns}>
                        <div className='article__view__related__posts' css={style.article__view__related__posts}>
                            <h2>Other related ADELAIDE <span style={{ color: '#ee1f3b' }}> AZ </span> articles </h2>
                            <div className='article__view__related__posts__c' css={style.article__view__related__posts__c}>
                                {
                                    article.relatedPosts.map((sItem, sIndex) => {
                                        return (
                                            <ArticleTile key={sIndex} article={sItem} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            <ViewPartial />
        </Fragment>
    )
});

export default memo(ArticleView);
//export default ArticleView;

function ArticleCategory({ category }) {

    const getAlpha = useCallback(() => {
        if (!category.categoryName || category.categoryName < 1) return "";
        return (category.categoryName[0]).toLowerCase();

        // eslint-disable-next-line
    }, []);

    return (
        <SiteLink className={`article__view__category alpha-${getAlpha()}`} css={style.article__view__category} to={category.categorySlug}>
            {category.categoryName}
        </SiteLink>
    )
}

const ArticleStructureMemo = memo(({article}) => {

    const articleStructuredData = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": article.title,
        //"description": article.title,
        "image": "https://adelaideaz.com" +  article.mainImageUrl,
        "datePublished": article.dateCreated,
        "dateModified": article.dateUpdated,
        "author": {
            "@type": "Organization",
            "name": "Adelaide AZ",
            "url": "https://adelaideaz.com"
        },
    }

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(articleStructuredData)}
            </script>
        </Helmet>
    )
});