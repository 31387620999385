//import { transform } from '@lib/animation/animationUtils';
import { mq } from '../../../../cssInJs';

export default {

    // search__box__c : {
    //     backgroundColor: variables.colorDarkBlue,
    //     paddingTop: '1rem',
    //     paddingBottom: '1rem'
    // },
    // search__box__i__g : {
    //     maxWidth: '360px',
    //     margin: 'auto',
    //     '.input-group-text' : {
    //         backgroundColor: '#00b17d',
    //         border: 'unset',
    //         borderRadius: 'unset',
    //         color: 'white'
    //     },
    //     '.form-control' : {
    //         borderRadius : 'unset'
    //     }
    // }

    featured__articles: {
        maxWidth : '960px',
        margin: 'auto',
        overflow: 'auto'
    },

    featured__error: {
        fontSize: '2rem',
        textAlign: 'center',
        color: 'red'
    },

    featured__articles__c: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',

        '.article__tile': mq({
            flex: ['1 0 100%', null, '1 0 50%', '1 0 33.3%'],
            maxWidth: ['100%', null, '50%', '33.3%' ],
            padding: '0.8rem 0.8rem',
            display: 'flex',
            flexDirection: 'column',
        })
    },


}