/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import utils from 'utils';
import { Loading } from '../../../../components';
import env from '../../../../env';
import style from "./featuredArticlesStyle";
import ArticleTile from "../../../article/articleTile/articleTile";
//import { MdCameraAlt } from "react-icons/md";
//import cmsUtils from '../../cmsUtils';
//import { AiOutlineSearch } from "react-icons/ai";
//import { Fragment } from 'react';

export function FeaturedArticles(props) {
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'featured__articles', item.cssClass || item.anchorName || '');
    const [articles, setArticles] = useState();
    const [pending, setPending] = useState(true);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = async () => {
            const res = await fetch(env.apiBase + "/api/article/getfeatured", {
                //method: 'post',
                signal: signal,
                // headers: { 'Content-Type': 'application/json' },
                // body: JSON.stringify({
                //     category: atdwType,
                //     town: hideSearch === 'off' ? 'ALL' : town
                // })
            });

            const data = await res.json();
            setArticles(data);
            setPending(false);
        };

        fetchData();
        return function cleanup() {
            abortController.abort();
        }

        // eslint-disable-next-line
    }, []);

    if (pending) {
        return (
            <Loading />
        )
    }

    return (
        <div className={cssClass} css={style.featured__articles} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                (articles && articles.statusCode && articles.statusCode === 500) ?
                    <div className='featured__error' css={style.featured__error}> 500 error </div> :
                    <div className='featured__articles__c' css={style.featured__articles__c}>
                        {
                            articles.map((item, index) => {
                                return (
                                    // <FeaturedArticle key={index} article={item} />
                                    <ArticleTile key={index} article={item} />
                                )
                            })
                        }
                    </div>
            }
        </div>
    )
}

// function FeaturedArticle({ article }) {
    
//     const imageUrl = utils.site.resourcePath(article.mainImageUrl);
//     const alpha = useMemo(() => {
//         let catName = "";
//         if (article.categoryName && article.categoryName.length > 1) {
//             catName = (article.categoryName[0]).toLowerCase();
//         }
//         return catName;
//     }, []);

//     const tooltip = article.photoCreditSource + (article.photoCreditName ? ' ' + article.photoCreditName : '') + (article.photoCreditDate ? ' [' + article.photoCreditDate + ']': '');

//     return (
//         <div className={`article__tile alpha-${alpha}`} css={style.article__tile}>
//             <SiteLink to={`/articles/${article.postSlug}`}>
//                 <div className='article__tile__bg' css={style.article__tile__bg}>
//                     <div style={{overflow : 'hidden', borderTopLeftRadius: '20px', borderTopRightRadius: '20px'}}>
//                         <div style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }} className='article__tile__bg__inner' css={style.article__tile__bg__inner}></div>
//                     </div>
//                     <div className='article__tile__svg'>
//                         <div className='article__tile__svg_c'>
//                             <MdCameraAlt />
//                             <div className='article__tile__tip'>
//                                 {tooltip}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </SiteLink>
//             <div className="article__tile__category" css={style.article__tile__category}>
//                 <SiteLink to={`/articles/${article.categorySlug}`}> {article.categoryName + " >"} </SiteLink>
//             </div>
//             <div className='article__tile__des' css={style.article__tile__des}>
//                 <SiteLink to={`/articles/${article.postSlug}`}>
//                     <div className='article__tile__title' css={style.article__tile__title}>
//                         {article.title}
//                     </div>
//                     <div className='article__tile__read' css={style.article__tile__read}>
//                         READ MORE+
//                     </div>
//                 </SiteLink>
//             </div>
//         </div>
//     )
// }