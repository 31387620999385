import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: 'white',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s',
    
  })),
  top__bar : mq({
    position : 'fixed',
    width : '100%',
    zIndex : 998,
    top : ['44px', null, '56px'],
    '.top__bar__1' : {
        borderBottom: '10px solid #f7941e',
    },
    '.top__bar__2' : {
        borderBottom: '15px solid #00004c',
    }
  }),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [27, null, 36],
    marginRight: ['4.5rem',null,'0']
  })),
  logoScrolled: css(mq({

  })),

  sub__text : mq({
    lineHeight: '1',
    textAlign: 'right',
    position : 'fixed',
    zIndex : '1000',
    right: ['60px',null,'85px'],
    cursor: 'pointer'
  })
}