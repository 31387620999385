/** @jsx jsx */
import { jsx } from '@emotion/core';
import { memo } from 'react';
import { AppVersion } from '../../components';

const AppUpdateView = (() => {

    return(
        <div style={{textAlign : 'center'}}>
            <h1>
                <strong>
                    Adelaide AZ App(PWA)
                </strong>
            </h1>
            <AppVersion />
        </div>
    )

});

export default memo(AppUpdateView);