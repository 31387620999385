/** @jsx jsx */
import { jsx } from '@emotion/core';
//import { useEffect, useState } from 'react';
import utils from 'utils';
//import { useGet, SiteLink } from '../../../../components';
//import env from '../../../../env';
import style from "./bannerArticlesStyle";
import cmsUtils from '../../cmsUtils';
import Slider from "react-slick";
import ArticleSearchBox from "../../../../site/article/articleSearchBox/articleSearchBox";
import { Fragment } from 'react';
//import { SearchBox } from "../../searchBox/view/searchBox";

export function BannerArticles(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'banner__articles', 'bannerWrapperSize--standard', item.cssClass || item.anchorName || '');
  const articles = getArticles(cmsUtils.payload(item, 'ArticleBanners'))

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const slides = articles.map((item, index) =>
    <ArticleItem item={item} key={index} cmsOption={cmsOption}></ArticleItem>
  );

  return (
    <Fragment>
      <div className={cssClass} css={style.banner__articlescontainer} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        <Slider {...settings} >
          {slides}
        </Slider>
      </div>
      <ArticleSearchBox />
    </Fragment>
  )
}

function getArticles(articlesSerialized) {
  if (articlesSerialized) {
    return JSON.parse(articlesSerialized);
  }
  return [];
}

function ArticleItem({ item }) {

  const cssClass = utils.classNames('cms_item', 'banner-item', 'bannerSize');
  const bgUrl = utils.site.resourcePath(item.mainImageUrl);

  return (
    <div className={cssClass} style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}>
      <div className='article__title' css={style.article__title}>
        {item.title}
      </div>
    </div>
  )
}